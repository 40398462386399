<template>
  <div class="main--wrap">
    <main>
      <div class="main-content">
        <router-view :key="$route.params.id" />
        <footer class="footer">
          <div class="container">
            <div class="footer-title">
              <span>Our mission</span> is to connect hearts
            </div>
            <div class="row">
              <div class="review">
                <div class="review-card">
                  <div>
                    <span class="icon-quotes"></span>
                  </div>
                  <div class="review-content">
                    There’s nothing in the world but the two hearts that are
                    beating together, the two people that almost always feel and
                    think the same. I’m very pleased with this site for giving
                    such an opportunity to all of us to find our real love.
                  </div>
                  <div class="review-author">
                    <div class="author-avatar">
                      <img src="/images/author1.jpg" alt="Georg and Alina" />
                    </div>
                    <div>
                      <div class="author-name">Georg and Alina</div>
                      <div class="date">24.12.2020</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="review">
                <div class="review-card">
                  <div>
                    <span class="icon-quotes"></span>
                  </div>
                  <div class="review-content">
                    For the first time it seemed to me quite tricky and so
                    unconvincing to look for love online here. But just now I’m
                    standing and looking at my husband with our baby in his
                    arms.
                  </div>
                  <div class="review-author">
                    <div class="author-avatar">
                      <img src="/images/author2.jpg" alt="John and Olga" />
                    </div>
                    <div>
                      <div class="author-name">John and Olga</div>
                      <div class="date">12.10.2020</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="review">
                <div class="review-card">
                  <div>
                    <span class="icon-quotes"></span>
                  </div>
                  <div class="review-content">
                    Today I’m meeting my beloved in real life. We’ll have a real
                    opportunity to be as close as never before. To tell the
                    truth I’m really sick and tired of having long distance
                    relationships. Of course thanks to this site we were able to
                    find each other ...
                  </div>
                  <div class="review-author">
                    <div class="author-avatar">
                      <img src="/images/author3.jpg" alt="Thomas and Sophie" />
                    </div>
                    <div>
                      <div class="author-name">Thomas and Sophie</div>
                      <div class="date">07.09.2020</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="copyright">
              2008-2021 © All rights reserved
            </div>
          </div>
        </footer>
      </div>
    </main>

    <AppSignUp />
    <button class="btn btn-green btn-fixed" @click="setSignUpModal">
      Create an account
    </button>
  </div>
</template>
<script>
import AppSignUp from "@/components/AppSignUp";
import signUpModalMixin from "@/signUpModalMixin";
import { mapActions } from "vuex";
import $ from "jquery";
export default {
  components: { AppSignUp },
  mixins: [signUpModalMixin],
  methods: {
    ...mapActions({
      fetchUsers: "fetchUsers",
      getIp: "getIp"
    }),
    async sendPidAndSid() {
      if (process.env.NODE_ENV === "development") return;
      let params = new URL(document.location).searchParams;
      let pid = params.get("pid"),
        sid = params.get("sid"),
        referer = document.referrer;
      $.ajax({
        url: "/api/?action=send_partner_info",
        method: "post",
        data: "pid=" + pid + "&sid=" + sid + "&referer=" + referer,
        crossDomain: true,
        success: function() {
          console.log("Success");
        }
      });
    }
  },
  mounted() {
    this.getIp();
    this.sendPidAndSid();
    this.fetchUsers();
  }
};
</script>
