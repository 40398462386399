<template>
  <div class="right-sidebar" @click.self="closeSignUpModal">
    <div class="sign-up">
      <div class="sign-up--wrap">
        <div class="sign-up--title">Sign Up <span>FREE</span></div>
        <form action="" @submit.prevent="submit">
          <div class="flex-grow">
            <AppInput
              name="name"
              placeholder="John"
              label="First name:"
              required
              prepend-icon="icon-user"
              v-model="form.name"
            />
            <AppInput
              name="email"
              placeholder="example@email.com"
              label="E-mail:"
              required
              prepend-icon="icon-envelope"
              type="email"
              v-model="form.email"
            />
            <AppInput
              name="pass"
              placeholder="******"
              label="Your password:"
              prepend-icon="icon-key"
              required
              append-icon="icon-outline_eye"
              :type="passwordType"
              :append-click="showPassword"
              v-model="form.password"
            />
            <div class="form-check">
              <label
                ><input
                  type="checkbox"
                  required="required"
                  name="sign_agree"
                  checked="checked"
                  class="checkbox"
                />
                <span>I agree with License agreement</span></label
              >
            </div>
          </div>
          <div class="form-buttons">
            <AppButton class="btn-green">Create an account</AppButton>
          </div>
        </form>
      </div>
    </div>
    <div class="copyright">
      2008-2021 © All rights reserved
    </div>
  </div>
</template>

<script>
import AppInput from "@/components/AppInput";
import AppButton from "@/components/AppButton";
import signUpModalMixin from "@/signUpModalMixin";
import { mapState } from "vuex";
import $ from "jquery";
export default {
  name: "AppSignUp",
  mixins: [signUpModalMixin],
  data() {
    return {
      passwordType: "password",
      form: {
        name: "",
        email: "",
        password: "",
        checked: false
      }
    };
  },
  components: { AppButton, AppInput },
  computed: {
    ...mapState({
      ip: state => state.ip
    })
  },
  methods: {
    showPassword() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    submit(evt) {
      evt.preventDefault();
      // gtag_report_conversion();
      let params = new URL(document.location).searchParams;
      let data = $(evt.target).serialize();
      let ip = this.ip;
      data +=
        "&pass2=" +
        $(evt.target.pass).val() +
        "&ip=" +
        ip +
        "&pid=" +
        params.get("pid") +
        "&pid_sid=" +
        params.get("sid");
      $.post(
        "/api/?action=registration",
        data,
        function(resp) {
          console.log("Success");
          if (resp.loc) {
            setTimeout(function() {
              document.location = resp.loc;
            }, 500);
          }
        }
        //,"script"
      );
    }
  }
};
</script>

<style scoped></style>
